let $ = require('jquery');
require('slick-carousel');
require('slick-carousel/slick/slick.scss');
require('slick-carousel/slick/slick-theme.scss');

$( document ).ready(function() {

    $('.homepage-rotator').slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        fade: true,
        cssEase: 'linear',
        pauseOnHover: true,
        pauseOnFocus: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            }
        ]
    });

    $(window).on('load', function(){

    let mapStyle = [
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#444444"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#f2f2f2"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#46bcec"
                },
                {
                    "visibility": "on"
                }
            ]
        }
    ];


    //google.maps.event.addDomListener(window, 'load', init);
    function init() {
        // Basic options for a simple Google Map
        // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
        let rijekaOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: 15,
            disableDefaultUI: true,
            // The latitude and longitude to center the map (always required)
            center: new google.maps.LatLng(45.325520, 14.444790),
            // How you would like to style the map.
            // This is where you would paste any style found on Snazzy Maps.
            styles: mapStyle
        };
        /*let masarykovaOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: 15,
            disableDefaultUI: true,
            // The latitude and longitude to center the map (always required)
            center: new google.maps.LatLng(45.810860, 15.972650),

            // How you would like to style the map.
            // This is where you would paste any style found on Snazzy Maps.
            styles: [{"featureType":"all","elementType":"all","stylers":[{"invert_lightness":true},{"saturation":10},{"lightness":30},{"gamma":0.5},{"hue":"#435158"}]}]
        };*/
        let gradaVukovaraOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: 15,
            disableDefaultUI: true,


            // The latitude and longitude to center the map (always required)
            center: new google.maps.LatLng(45.799070, 15.959410),

            // How you would like to style the map.
            // This is where you would paste any style found on Snazzy Maps.
            styles: mapStyle
        };
        /*let heinzlovaOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: 15,
            disableDefaultUI: true,

            // The latitude and longitude to center the map (always required)
            center: new google.maps.LatLng(45.812565, 15.999529),
            // How you would like to style the map.
            // This is where you would paste any style found on Snazzy Maps.
            styles: mapStyle
        };*/

        // Get the HTML DOM element that will contain your map
        // We are using a div with id="map" seen below in the <body>
        /*let rijeka = document.getElementById('rijeka');
        let masarykova = document.getElementById('masarykova');*/
        let gradaVukovara = document.getElementById('gradaVukovara');
        /*let heinzlova = document.getElementById('heinzelova');*/

        // Create the Google Map using our element and options defined above
        /*let rijekaMap = new google.maps.Map(rijeka, rijekaOptions);
        let masarykovaMap = new google.maps.Map(masarykova, masarykovaOptions);*/
        let gradaVukovaraMap = new google.maps.Map(gradaVukovara, gradaVukovaraOptions);
        /*let heinzlovaMap = new google.maps.Map(heinzlova, heinzlovaOptions);*/

        // Let's also add a marker while we're at it
        /*let rijekaMarker = new google.maps.Marker({
            position: new google.maps.LatLng(45.325520, 14.444790),
            map: rijekaMap,
            title: 'iService Scarpina 3'
        });

        let masarykovaMarker = new google.maps.Marker({
            position: new google.maps.LatLng(45.810860, 15.972650),
            map: masarykovaMap,
            title: 'iService Masarykova 18'
        });*/

        let gradaVukovaraMarker = new google.maps.Marker({
            position: new google.maps.LatLng(45.799070, 15.959410),
            map: gradaVukovaraMap,
            title: 'iService grada Vukovara 20'
        });

        /*let heinzlovaMarker = new google.maps.Marker({
            position: new google.maps.LatLng(45.812565, 15.999529),
            map: heinzlovaMap,
            title: 'Heinzlova 4a'
        });*/
    }
    init();

    });
});